import React from 'react'
import ListCss from "./list.module.scss";

/**
 * Puts all rendered components of a page in a list 
 *
 */
function List(props) {

    const {value} = props
    // console.log(value);

    return (
      <>
        <div className={`${ListCss.app_main_layout_content}`}>
          <div role="list" className={`${ListCss.list}`} id="lists">
            {value}
          </div>
        </div>
      </>
    );
}

export default List