import React from 'react'

function SpaceBlock(props) {

  const {space} = props
  
  return (
    <div style={{marginTop:`${space ? space : "20"}%`}} className='space-block'></div>
  )
}

export default SpaceBlock