import React from 'react'

function PaymentCard(props) {
    const {id, order, children} = props
  return (
    <>
    {children &&
        <li
        id={`card${id}`}
        style={{ animationDelay: `${order * 0.05}s` }}
        className="cards card-animation"
        key={id}
      // onClick={e=> GAEventsTracker("Card url" , url)}
      >
        <div >
            {children}
        </div>
    </li>

}
</>
  );
}

export default PaymentCard