import React from 'react'
import { useFormContext } from "react-hook-form";
import FormStyle from "./fom.module.scss";
import TypoCss from '../../text/typography.module.scss';


function Input(props) {
  
  const { register } = useFormContext();

  const determineInputType = () => {
    switch (props.InputType) {

      case "input":
        return (
          <input
            placeholder={props.placeholder}
            {...register(`${props.name}`)}
            type={props.typeOfTheInput}
            className={`${FormStyle.tabclix_input_field} ${FormStyle.tabclix__w_input}`}
            maxLength={props.maxlength}
            name={props.name}
            data-name={props.dataName}
            id={props.idofInput}
            required={props.required}
          />
        );

      case "option":

        return (
          <select
            id={props.id}
            name={props.name}
            data-name={props.dataName}
            {...register(`${props.name}`)}
            className={`${FormStyle.tabclix_input_field} w-select`}
          >
            {props.inputOptions.map(function (opt) {
              if (opt.selected) {
                return (
                  <option value={opt.value} key={opt.value} selected>
                    {opt.content}
                  </option>
                );
              } else {
                return (
                  <option value={opt.value} key={opt.value}>
                    {opt.content}
                  </option>
                );
              }
            })}
          </select>
        );

      case "optgroup":
        const eactoptgroup = props.optgroup.map((group) =>
          <optgroup label={group.label} {...register(`${props.name}`)}>
            {group.options.map(opt => <option value={opt.value} key={opt.value}>{opt.content}</option>)}

          </optgroup>
          
        );

        return (
          <select
            className={`${FormStyle.tabclix_input_field} w-select`}
            {...register(`${props.name}`)}
          >
            {eactoptgroup}
          </select>
        );
    }
  }
  

  
  return (
    <div className="form-item-block">
      <label for={props.forLabel} className={`${TypoCss.tabclix__label}`}>{props.LabelName}</label>
      {determineInputType()}
      <div></div>
    </div>
  );
}

export default Input