import React from 'react'
import { donationFormActions } from "../../../store/form-donation-slice";
import { useDispatch } from 'react-redux';
import useActionHook from '../../../hooks/useActionHook';
import DonationBtnCss from "./donationButton.module.scss";
import TypoCss from "../../text/typography.module.scss";



function DonationButton(props) {
    const dispatch = useDispatch();
    const {value, state} = props;

    const onHandleClick = (e) =>{
        dispatch(donationFormActions.setDonationAmmount(+e.target.value));
    }

    
    return (
      <div className="" onClick={onHandleClick}>
        <button
          disabled={state}
          type="submit"
          value={value}
          className={`${DonationBtnCss.tabclix_donation_button}  ${TypoCss.tabclix__text_large}`}
          onClick={useActionHook(props)}
        >{`${value}$`}</button>
      </div>
    );
}

export default DonationButton