import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import i18n from "i18next";
import {initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import App from './App';
import MessageProvider from './components/messages/MessageProvider';
import { Provider } from 'react-redux'
import store from './store/index'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    load: "currentOnly",
    backend: {
      loadPath: (lng, ns) => {
        const url = `https://tabclixdata.s3.ca-central-1.amazonaws.com/assets/locales/${lng}/translate.json`;
        return url;
      },
      crossDomain: true,
    },
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)

    crossDomain: true,
    // lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    react: {
      useSuspense: false,
    },

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    detection: {
      order: ["navigator"],
    },
  });

  if (process.env.NODE_ENV !== 'development') {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
    console.info = () => {}
    console.warn = () => {}
  }

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <MessageProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </MessageProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("tabclix___div_root")
);
