import React from 'react';
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Emailstyle from "../fom.module.scss";
import TypoCss from "../../../text/typography.module.scss";



function EmailElement() {

  const {t} = useTranslation();

    const {
      register,
      formState: { errors },
    } = useFormContext();

  return (
    <>
      <label htmlFor="" className={TypoCss.tabclix__label}>{t("email")}</label>
      <input
        type="email"
        className={`${Emailstyle.tabclix_input_field} ${Emailstyle.tabclix__w_input}`}
        {...register("CustomerEmail")}
      />
      <p className={Emailstyle.error_form_message}>{errors.CustomerEmail?.message}</p>
    </>
  );
}

export default EmailElement