import React from 'react';
import TypoCss from "../../text/typography.module.scss";


function Inputgroup(props) {

    const { label } = props;
    
  return (
    <div className="form-item-block">
      <label htmlFor="" className={TypoCss.tabclix__label}>{label}</label>
      {props.children}
    </div>
  );
}

export default Inputgroup