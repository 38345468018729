import React, { useState } from 'react';
import { useForm, FormProvider } from "react-hook-form";
import Input from './Input';
import Button from '../button/Button';
import H1 from '../../text/H1';
import P1 from '../../text/P1';
import { firstName, lastName, button, email } from './newsLetterData';
import axios from 'axios';
import FormStyles from "./fom.module.scss";




const client = require("@mailchimp/mailchimp_marketing");

function NewsLetter(props) {

    const formMethods = useForm();
    const [ isSuccessfullySubmitted, setIsSuccessfullySubmitted ] = useState();
    const [ isError, setIsError ] = useState();
    const [ isLoading, setIsLoading ] = useState(false);
    console.log(props);

    const {
        handleSubmit,
        formState
    } = formMethods;



    const onSubmit = (data) => {
        setIsLoading(true);
        console.log(data);
        let config = {
            method: "post",
            url: `${process.env.REACT_APP_CMS_URL}/api/subscribe`,
            data: {
              userID: "",
              subscriberData: data
              
            },
        };
        
        
        axios(config)
        .then((res => {
            setIsLoading(false);
            setIsSuccessfullySubmitted(true);
            const timeSuccess = setTimeout(() => {
                // After 3 seconds set the show value to false
                setIsSuccessfullySubmitted(false)
              }, 4000)
              clearTimeout(timeSuccess);

            // const myData = res.data;

        }))
        .catch(err => {
            const timeError = setTimeout(() => {
                // After 3 seconds set the show value to false
                setIsError(true);
              }, 4000)
              clearTimeout(timeError);
        });
 
    };

    return (
        <>
            <H1 text={props.title} />
            <P1 text={props.description} />
        {isSuccessfullySubmitted && (
            <div className={`${FormStyles.form_success_submission}`}>You successfully subscribed</div>
            )}
        {isError && (
            <div className={`${FormStyles.form_error_submission}`}>Subsciption unsuccessful</div>
            )}
            <FormProvider {...formMethods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Input {...firstName} required={true}/>
                    <Input {...lastName} required={true}/>
                    <Input {...email} required={true}/>
                    <Button {...button} buttonText={props.button_text} disabled={isLoading}/>
                </form>
            </FormProvider>
        </>
    );
}

export default NewsLetter