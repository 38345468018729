import React from "react";
import { useTracking } from "react-tracking";



export const useSortEvents = (props) => {

  const { trackEvent } = useTracking();

  return () => {
    if (props.action.type === "call" || props.action.type === "mail" || props.action.type === "link") {
      trackEvent({
        TrackingType: 'ActionEvent',
        TrackEvent: "click",
        TrackEventType: `${props.action.type}`,
        TrackActionRedirect: `${props.url}`
      });
    }else if (props.action.type === "locationLink"){
      trackEvent({
        TrackingType: 'ActionEvent',
        TrackEvent: "click",
        TrackEventType: `${props.action.type}`,
        TrackActionRedirect: `${props.address}`
      });
  }
  };
};
