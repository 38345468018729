function haversine_distance(mk1, mk2) {
  console.log(mk1);
  console.log(mk2);
    var R = 6371.0710;
    var rlat1 = mk1.lat * (Math.PI/180); // Convert degrees to radians
    var rlat2 = mk2.lat * (Math.PI/180); // Convert degrees to radians
    var difflat = rlat2-rlat1; // Radian difference (latitudes)
    var difflon = (mk2.lng-mk1.lng) * (Math.PI/180); // Radian difference (longitudes)

    var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
    return d;
  }

// var distance = haversine_distance(mk1, mk2);
// console.log(distance);




// used to sort LOCATIONS components that are child or parent ids
  
  export const sortParentIdComponent = (dataSearch) =>{

  var geocoderLocation = [];
  console.log(`STEP 1`);
  var dataCopy = JSON.parse(JSON.stringify(dataSearch));

    var sortComponents = [];
    var sortLocations = [];
    var sortLocationsCopy = [];
    var userLocation = getUserPosition();
    var count = 0;

    var myLatlng1 = new window.google.maps.LatLng(45.512757, -73.459746);
    var mk1 =  new window.google.maps.Marker({
        position: myLatlng1,
        title:"Marker 1"
    });

    dataCopy.map(function (eachObject) {
        
        if(eachObject.action.type === 'locationLink'){
          
          console.log(`STEP 2`);

                // console.log("Distance in Kilometers: ", (distanceInMeters * 0.001).toFixed(2));  
                // TODO 1: assign to each the distance compared to the user
                // eachObject.order = (distanceInMeters * 0.001).toFixed(2);
                // console.log(eachObject);
                console.log(`STEP 3`);
                sortLocations.push(eachObject);
                console.log(`STEP 4`);
                sortLocations.sort((a, b) => (a.order > b.order) ? 1 : -1);

            // TODO 2: add into array the element
            console.log(`STEP 5`);
            console.log(sortLocations);
            
            // TODO 3: change order of sort in array and put them from 1
            // TODO 4: sort location array with new data
            
            // const test = [12.62, 2.62, 12.67];
            // test.sort( (a, b) => (a > b) ? 1 : -1);
            // console.log(test);
            
            // console.log(sortLocations);
            
          }else{
            console.log(`STEP 1.1`);
            sortComponents.push(eachObject)
            sortComponents.sort((a, b) => (a.order > b.order) ? 1 : -1);
          }
        })
        // console.log(dataCopy);
        // console.log(sortComponents.concat(sortLocations));
        console.log(`STEP 6`);
        console.log(sortLocations);
        
    return sortLocations.concat(sortComponents);
}

const successCallback = (position) => {
    console.log(position);
  };
  
const errorCallback = (error) => {
    // console.log(error);
  };


export const getUserPosition = () => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
      } else {
        // console.error("Geolocation is not supported by this browser.");
      }
}

function showPosition(position) {
    // console.log(`Latitude: ${position.coords.latitude}, longitude: ${position.coords.longitude}`);
  }





 // sort components that dont use parent ids and dont really have children
export const sortNoIdComponents = (AllData) =>{
    var emptycomponent = <></>;
    let sortComponents = []
    emptycomponent = AllData.map(eachObject => {
                sortComponents.push(eachObject)
                sortComponents.sort((a, b) => (a.order > b.order) ? 1 : -1);
    })
    return sortComponents
} 




// Actual version
export const  sortParentIdComponents = (dataSearch, AllData) =>{
    
    let sortComponents = [];
    let sortLocations = [];
    // const userLocation = getUserPosition();
    var emptycomponent = AllData.map(function (eachObject) {
         eachObject.parent_ids.map(function (eachIdofeachComponent) {

            if (eachIdofeachComponent === dataSearch) {
                // console.log(eachObject);
                
                // if(eachObject.action.type === 'locationLink'){
                //     // TODO 1: assign to each the distance compared to the user
                //     // TODO 2: add into array the element
                //     // TODO 3: sort location array with new data
                //     sortLocations.push(eachObject);
                //     sortLocations.sort((a, b) => (a.order > b.order) ? 1 : -1);


                    

                // }else{

                    sortComponents.push(eachObject)
                    sortComponents.sort((a, b) => (a.order > b.order) ? 1 : -1);
                // }
            }
        })
    })

    return sortComponents.concat(sortLocations);
} 

// function haversine_distance(mk1, mk2) {
//     var R = 6371.0710;
//     var rlat1 = mk1.position.lat() * (Math.PI/180); // Convert degrees to radians
//     var rlat2 = mk2.position.lat() * (Math.PI/180); // Convert degrees to radians
//     var difflat = rlat2-rlat1; // Radian difference (latitudes)
//     var difflon = (mk2.position.lng()-mk1.position.lng()) * (Math.PI/180); // Radian difference (longitudes)

//     var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
//     return d;
//   }
  
// var mk2 = new google.maps.Marker({position: frick, icon: {
//   path: google.maps.SymbolPath.CIRCLE,
//   scale: 0
// }});
// var mk1 =  new google.maps.Marker({position: posac, map: map, icon: {
//   path: google.maps.SymbolPath.CIRCLE,
//   scale: 0
// }});
  
// var distance = haversine_distance(mk1, mk2);

// $('#'+i).find('#distance').html(parseFloat(distance).toFixed(1)+' km');
// $('#'+i).attr('sort', parseFloat(distance).toFixed(1));


// let sortedArr = children.map(e => e.getAttribute('sort')).sort((a, b) => a.localeCompare(b));
// children.forEach(child => {
// child.style.order = sortedArr.indexOf(child.getAttribute('sort'))
// })


// Google maps call Api version 
// export const sortParentIdComponent = (dataSearch) =>{

//   var geocoderLocation = [];

//   console.log(`STEP 1`);
//   var dataCopy = JSON.parse(JSON.stringify(dataSearch));

//     var sortComponents = [];
//     var sortLocations = [];
//     var sortLocationsCopy = [];
//     var userLocation = getUserPosition();
//     var count = 0;

//     var myLatlng1 = new window.google.maps.LatLng(45.512757, -73.459746);

//     var mk1 =  new window.google.maps.Marker({
//         position: myLatlng1,
//         title:"Marker 1"
//     });

//     dataCopy.map(function (eachObject) {
        
//         if(eachObject.action.type === 'locationLink'){
//           var geocoder = new window.google.maps.Geocoder();
//           console.log(`STEP 2`);
//           geocoder.geocode( {'address': eachObject.address }, function(results, status) {
//             if (status == window.google.maps.GeocoderStatus.OK) {
//               var myLatlng2 = new window.google.maps.LatLng(results[0].geometry.location.lat(),results[0].geometry.location.lng());
//               var mk2 =  new window.google.maps.Marker({
//                       position: myLatlng2,
//                       title:"Marker 2"
//                     });
                    
//                     var distanceInMeters = window.google.maps.geometry.spherical.computeDistanceBetween(
//                       mk1.getPosition(),
//                       mk2.getPosition()
//                       );
                      
//                       // console.log("Distance in Kilometers: ", (distanceInMeters * 0.001).toFixed(2));
                      
//                 // TODO 1: assign to each the distance compared to the user
//                 eachObject.order = (distanceInMeters * 0.001).toFixed(2);
//                 // console.log(eachObject);
//                 console.log(`STEP 3`);
//                 sortLocations.push(eachObject);
//                 console.log(`STEP 4`);
//                 sortLocations.sort((a, b) => (a.order > b.order) ? 1 : -1);
//               } else {
//                 alert('Geocode was not successful for the following reason: ' + status);
//               }
//             });
            
//             // TODO 2: add into array the element
//             console.log(`STEP 5`);
//             console.log(sortLocations);
            
//             // TODO 3: change order of sort in array and put them from 1
//             // TODO 4: sort location array with new data
            
//             // const test = [12.62, 2.62, 12.67];
//             // test.sort( (a, b) => (a > b) ? 1 : -1);
//             // console.log(test);
            
//             // console.log(sortLocations);
            
//           }else{
//             console.log(`STEP 1.1`);
//             sortComponents.push(eachObject)
//             sortComponents.sort((a, b) => (a.order > b.order) ? 1 : -1);
//           }
//         })
//         // console.log(dataCopy);
//         // console.log(sortComponents.concat(sortLocations));
//         console.log(`STEP 6`);
//         console.log(sortLocations);
        
//     return sortLocations.concat(sortComponents);
// }
