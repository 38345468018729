import { createSlice } from "@reduxjs/toolkit";
// import data from '../JSONs/dataV2Template.json'


// const starterPages = data.filter(allData => allData.type === "Page");
// const current = data.filter(allPages => allPages.id === 0);
// const navBar = data.filter(allData => allData.type === "navIcon");

const initialState = {
    AllData: [], 
    pages: [],
    currentPage: null,
    currentSubContentId: null,
    navBar:[],
    headers:[],
    navigationOrder:[],
    thankYouPage: {}
};

// You can put every slice in its own file
export const DataSlice = createSlice({
  name: "tabclixData",
  initialState: initialState,
  reducers: {
    replaceAllData(state, action) {
      // When using react toolkit, we ar not mutating the state
      // the toolkit will capture the code, use a third party library "Immer" https://immerjs.github.io/immer/ to
      // ensure that this is actually translated to some immutable code instead of manipulating the existing one
      state.AllData = action.payload;
    },
    replaceNavBar(state, action) {
      state.navBar = action.payload;
    },
    replacePages(state, action) {
      state.pages = action.payload;
    },
    replaceHeader(state, action) {
      state.headers = action.payload;
    },
    replaceTankyouPage(state, action) {
      state.thankYouPage = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentSubContentId = null; // put to null or will not change page in subcontent mode
      state.currentPage = action.payload;
    },
    setSubContent(state, action) {
        if (state.currentSubContentId !== null) {
          state.navigationOrder.push(state.currentSubContentId);
        }
        state.currentSubContentId = action.payload.id;
    },
    setDirectSubcontent(state, action){
       state.currentSubContentId = action.payload;
    },
    addToGoBack(state, action) {
      // sets the state to the previous id in the array
      state.navigationOrder.push(action.payload);
    },
    goBack(state) {
      const backButton = state.navigationOrder.pop();

      if (backButton !== undefined) {
        state.currentSubContentId = backButton;
      } else {
        state.currentSubContentId = null;
        state.currentPage = state.currentPage;
      }
      // send back last array Id
    },
  },
});

//To get hold of  unique action identifiers, 
//we can use the counterSlice and access .actions = object full of keys that match the reducers name in the reducer area
// we should also be able to export the actions 
export const tabclixActions = DataSlice.actions;

// we dont export the entire slice but only its reducers 
export default DataSlice.reducer;