export const firstName = {
    
    "name": "firstName",
    "type": "input",
    "order": 1,
    "rules": {
        "required": true
    },
    "action": {
        "type": ""
    },
    "dataName": "firstName",
    "forLabel": "firstName",
    "InputType": "input",
    "LabelName": "firstName",
    "idofInput": "firstName",
    "maxlength": "256",
    "parent_ids": [
        665
    ],
    "placeholder": "",
    "defaultValue": "",
    "typeOfTheInput": "text"
}
export const lastName = {
    
    "name": "lastname",
    "type": "input",
    "order": 1,
    "rules": {
        "required": true
    },
    "action": {
        "type": ""
    },
    "dataName": "lastname",
    "forLabel": "lastname",
    "InputType": "input",
    "LabelName": "lastname",
    "idofInput": "lastname",
    "maxlength": "256",
    "parent_ids": [
        665
    ],
    "placeholder": "",
    "defaultValue": "",
    "typeOfTheInput": "text"
}

export const button  =  {
    "id": 645,
    "name": "test3",
    "type": "button",
    "label": "Êtes-vous d'accord avec les termes et conditions ?",
    "order": 10,
    "state": "",
    "action": {
        "type": ""
    },
    "parent_ids": [
        665
    ]
}

export const email = {
    "id": 556,
    "name": "email",
    "type": "input",
    "order": 1,
    "rules": {
        "required": true
    },
    "action": {
        "type": ""
    },
    "dataName": "email",
    "forLabel": "email",
    "InputType": "input",
    "LabelName": "Adresse électronique",
    "idofInput": "email",
    "maxlength": "256",
    "parent_ids": [
        665
    ],
    "placeholder": "",
    "defaultValue": "",
    "typeOfTheInput": "email"
}