import React from 'react';
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormCSS from "../../../common/form/fom.module.scss";
import TypoCss from "../../../text/typography.module.scss";


function MonthPicker(props) {

  const {t} = useTranslation();
    const {onChange} = props;
    const {
      formState: { errors },
    } = useFormContext();

    const allMonths = {
      1: t("short_jan"),
      2: t("short_feb"),
      3: t("short_mar"),
      4: t("short_apr"),
      5: t("short_may"),
      6: t("short_jun"),
      7: t("short_jul"),
      8: t("short_aug"),
      9: t("short_sept"),
      10: t("short_oct"),
      11: t("short_nov"),
      12: t("short_dec"),
      
    };
    
   const months = Object.keys(allMonths).map((month, monthNumber) => (
     <option key={`${month}${monthNumber}${allMonths[month]}`} value={`${month}`}>
       {allMonths[month]} - {month}
     </option>
   ));

  return (
    <>
      <label htmlFor="" className={TypoCss.tabclix__label}>{t("expiration_month")}</label>
      <select
        id="months"
        className={`${FormCSS.tabclix_input_field} ${FormCSS.tabclix__w_input}`}
        defaultValue="MM"
        onChange={onChange}
      >
        <option hidden value="MM" className={`${FormCSS.hidden_input}`}>
          MM
        </option>
        {months}
      </select>
      <p className={`${FormCSS.error_form_message}`}>
        {errors.expiryMonth?.message}
      </p>
    </>
  );
}

export default MonthPicker