import React, { useState } from 'react'
import $ from 'jquery'
import {removeAllClass} from '../../helpers/remove-class'
import styled from 'styled-components';
import Formcss from "./form/fom.module.scss"; 

const SearchStyle = styled.div`
  margin-top: 30px;
  margin-bottom: 43px;
`;

const SearchIput = styled.input`
  height: 40px;
  border: 0px;
`;
function Search() {


  const [searchItem, setSearchItem] = useState('');

      $("#lists li div").filter(function() {
            removeAllClass(".card_target");
            // removeClass(".cardlogo", "icon-animation");
            // removeClass(".cardFinder", "card-text-animation");
        $(this).toggle($(this).text().toLowerCase().indexOf(searchItem)> -1);
      });



  return (
    <SearchStyle className="tabclix-search-container">
      <form action="/search" className="tabclix-w-form">
        <SearchIput
          type="search"
          onChange={(e) => {
            setSearchItem(e.target.value);
          }}
          className={`search-input ${Formcss.tabclix__w_input}`}
          maxLength="256"
          name="query"
          placeholder="Search…"
          id="search"
          required=""
        />
        {/* <input type="submit" value="Search" className=" w-button search-button" /> */}
      </form>
    </SearchStyle>
  );
}

export default Search
