import axios from "axios";

const axiosParams = {
    baseURL :process.env.REACT_APP_CMS_URL,
}

const axiosInstance = axios.create(axiosParams);

const api = () => {
    return {
        get:(url, config = {})=> axiosInstance.get(url, config),
        post:(url,body, config = {})=> axiosInstance.post(url, body, config)
    }
}

export default api(axiosInstance)