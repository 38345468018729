import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initializeAppData } from "./store/data-action";
// import { initializeAppData } from "./store/data-actiondev";
import "./styles/css/normalize.css";
import "./styles/css/webflow.css";
import "./styles/css/global_tabclix.css";
import MyRoutes from "./components/MyRoutes";
import { useTranslation } from "react-i18next";
import track from "react-tracking";
import Navbar from "./layout/Navbar";
import PageApp from "./layout/PageApp";
/**
 * Highest component in the hierarchy
 */
function App() {
  const currentPage = useSelector((state) => state.datareducer.currentPage);
  const allData = useSelector((state) => state.datareducer.AllData)
  const nav = useSelector(state => state.datareducer.navBar);
  const dispatch = useDispatch();
  const { t } = useTranslation();


  /**
   * React toolkit also accepts actions creators that return function.
   * If it sees that you dispatch an action which is actually a function,
   * it will execute that function for you
   *
   * @param {none}
   *
   */
 

  useEffect(() => {
    
    dispatch(initializeAppData());

    // var userLang = navigator.language || navigator.userLanguage;
    // alert("The language is: " + userLang);

    
  }, []);

  return (
    <>
      <MyRoutes />
      <main>
        {currentPage ? <PageApp /> : "" }
        {/* <Navbar  /> */}
      </main>
    </>
  );
}


const TrackedApp = track(
  // app-level tracking data
  { TbxAnalyticsSourceApp: `${document.querySelector("#tbx_emb_src")?JSON.stringify(document.querySelector("#tbx_emb_src").getAttribute("code")):'unknownUser'}` },
  // { TbxAnalyticsSourceApp: `tab`},

  // top-level options
  {
    // custom dispatch to console.log in addition to pushing to dataLayer[]
    dispatch: (data) => {
      try {
        // console.log(data);
        const url = `${process.env.REACT_APP_CMS_URL}/api/analytics`;
        const mydata = data;
        const axios = require('axios');
        axios.post(url, mydata);
      } catch (error) {
        // console.error(error);
      }
    },
  }
)(App);

export default TrackedApp;
