import { tabclixActions } from "./data-slice";
import i18next from "i18next";
import { getTabclixCode } from "../helpers/embed-code";
import { fetchAuth,fetchCss, fetchContent } from "../api/tabclixContentApi";
// THUNKS PART - IMPORTANT TO PUT LOGIC AFTER THE SLICE(if inside slice file)


// here we create a function - which immediately returns another function
export const initializeAppData = () => {
  // this function can be async 
  return async (dispatch) => {

    // we create a new async function where we send the request  
    const fetchAllData = async () => {
      // const response = await fetch(`https://v3.tabclix.com/api/get-data`);

      /**
       * Here we fetch the Css, for now it will stay here because it is the fastest way it applies css
       */
        try {
          
          const authResponse = await fetchAuth()
          const authorizationData = await authResponse.data;
          const authToken = authorizationData.access_token

          const css = await fetchCss(authToken);
          const appcss = await css.data;

          const content = await fetchContent(authToken);
          const appCont = await content.data;

          Object.keys(appcss).map(function (key, index) {
            document.documentElement.style.setProperty(`${key}`, `${appcss[key]}`);
            const color = getComputedStyle(document.documentElement).getPropertyValue(`${key}`);
          });


          return appCont;

        } catch (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // You could return a custom error object or message here
            return { error: 'The API call failed' };
          } else if (error.request) {
            // You could return a custom error object or message here
            return { error: 'No response was received from the API' };
          } else {
            // You could return a custom error object or message here
            return { error: 'An error occurred while making the API call' };
          }
        }
 
    }


    const findSpecificData = (allData, componentName) => {
      const componentData = allData.filter(Data => Data.type === componentName);
      return componentData;
    }

    try {
      const allData = await fetchAllData();

      const headers = findSpecificData(allData, "header");

      dispatch(tabclixActions.replaceHeader(headers));

      dispatch(tabclixActions.replaceAllData(allData));

      const navIcon = findSpecificData(allData, "navIcon");

      dispatch(tabclixActions.replaceNavBar(navIcon));

      const thankyoupage = findSpecificData(allData, "thankyoupage");
      dispatch(tabclixActions.replaceTankyouPage(thankyoupage[0]));

      const language = findSpecificData(allData, "language");
      i18next.changeLanguage(language[0].default_language);

    } catch (error) {
      
    }

  }

}