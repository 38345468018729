import React from 'react'
import ImageCss from "./image.module.scss";
/**
 * Shows Image component
 *
 */
function Image(props) {

  const { path, localPath} = props;

  const imagePath = path ? path : `${process.env.REACT_APP_ICON_URL}/images/${localPath}`;

 

  return (
    <div>
      <img
        crossorigin
        src={imagePath}
        alt=""
        lazyLoad
        className={ImageCss.image}
      />
    </div>
  );
}

export default Image