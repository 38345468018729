import React, { useEffect, useState } from 'react';
import './Messages.css'


function Messages(props) {

    const [width, setWidth] = useState(0);
    const [exit, setExit] = useState(false)
    const isImportant = props.important;

    
    const handleStartTimer = () => {
        const id = setInterval(() => {
            setWidth(prev => {
                if (prev < 100) {
                   
                    return prev + 0.5;
                }
             
                clearInterval(id)
                return prev

            })
        }, 15) // -> runs every 15 miliseconds we make 200iteration * 15 = 3sec
    }

    useEffect(() => {
        if (width === 100) {
            handleCloseNotification()
        }
    }, [width]);


    useEffect(() => {
        if (!isImportant){
            handleStartTimer()
        }
    }, []);

    const handleCloseNotification = () => {

       
        setExit(true)
        setTimeout(() => {
            // removes from the dom
            props.dispatch({
                type: "REMOVE_MESSAGE",
                id: props.id
            })
        }, 400) //->removes after 400ms 
    }


    return <div
        className={`message-item ${props.type} `}
        >
        <div className='inner-border'>
         {props.title? <h5 className='title-message'>{ props.title}</h5> :""}
        <div className='align-button'>
        <p> {isImportant? "open untill x clicked":props.message}</p>
        {isImportant? <button className={'close-button'} onClick={handleCloseNotification} > close </button>:""}
        </div>
        {!isImportant?<div className={"bar"} style={{ width: `${width}%` }}></div>:""}
        </div>
    </div>;
}

export default Messages;
