import React from 'react'

function Icon({icon}) {
  return (
      <span
        className={`tbx-icon ${icon}`}
      ></span>
  );
}

export default Icon