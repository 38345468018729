import React from 'react';
import navCss from './navbar.module.scss';
import { useDispatch, useSelector } from "react-redux";
import { sortNoIdComponents } from '../helpers/sort-components';
import RenderedComponent from './RenderedComponent';
import NavBarIcon from '../components/common/NavBarIcon';
/**
 * Responsible for the logic of the navigation bar
 * 
 */
function Navbar() {

  const nav = useSelector(state => state.datareducer.navBar);

  let sortComponents = sortNoIdComponents(nav); 
  const component = sortComponents.map(orderedComponent => <RenderedComponent value={orderedComponent} />)


  return (
    <>
      <div className={navCss.mobile_bar}>
        <div className={navCss.mobile_bar_menu}>
          <NavBarIcon icon="tbx-info-outline"  />

        </div>
      </div>
    </>
  );
}
export default Navbar;