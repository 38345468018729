import { useDispatch, useSelector } from "react-redux";
import { tabclixActions } from "../store/data-slice";
import FileSaver from "file-saver";
import vcard from '../helpers/VCard/version 3';




const useActionHook = (props) => {

  const dispatch = useDispatch();
  const user = useSelector((state) => {
    if (props.action.type === "vcfDownload") {
      return state.datareducer.AllData;
    }
    return null;
  });


  return function action() {
    switch (props.action.type) {

      case "openPage":
        return dispatch(tabclixActions.setCurrentPage(props.id));
      case "locationLink":
        return (window.parent.location.href = props.url);

      case "subcontent":
        return dispatch(tabclixActions.setSubContent({ id: props.id }));

      case "changePage":
        // @Change
        return dispatch(tabclixActions.setCurrentPage(props.id));
      // return dispatch(tabclixActions.setCurrentPage(props.id));

      case "link":
      case "facebook":
      case "messenger":
      case "linkedin":
      case "Whatsapp":
      case "instagram":
      case "tiktok":
        if (!!props.url && !/^https?:\/\//i.test(props.url)) {
          props.url = 'http://' + props.url;
        }
        return (window.parent.location.href = props.url);

      case "popup":
        return alert("Welcome to Tabclix");

      case "call":
        let phoneNumberWithExtension = props.url;
        if (props.extension) {
          phoneNumberWithExtension += ',,' + props.extension;
        }
        console.log(phoneNumberWithExtension);
        return window.location.assign(`tel:${phoneNumberWithExtension}`);

      case "mail":
        return window.location.assign(`mailto:${props.url}`);

      case "vcfDownload":
        // console.log(props);

        const { generate } = vcard;

        const { name_prefix, name_suffix, vCard_lastName, vCard_firstName, vCard_title } = props;


        return user.map((object, keys) => {
          if (props.action.user === object.id) {
            const vcardContent = generate({
              name: {
                familyName: `${object.vCard_lastName ? object.vCard_lastName : ""}`,
                givenName: `${object.vCard_firstName ? object.vCard_firstName : ""}`,
                middleName: `${object.vCard_middleName ? object.vCard_middleName : ""}`,
                prefix: `${object.vCard_name_prefix ? object.vCard_name_prefix : ""}`,
                suffix: `${object.vCard_name_suffix ? object.vCard_name_suffix : ""}`,
              },
              formattedNames: [{
                text: `${object.vCard_formattedNames ? object.vCard_formattedNames : ""}`,
              }],
              nicknames: [{
                text: `${object.vCard_nickname ? object.vCard_nickname : ""}`,
              }],
              works: [{
                organization: `${object.vCard_organization ? object.vCard_organization : ""}`,
                title: `${object.vCard_title ? object.vCard_title : ""}`,
                role: `${object.vCard_work_role ? object.vCard_work_role : ""}`,
              }],
              emails: [{
                type: 'work',
                text: `${object.vCard_work_email ? object.vCard_work_email : ""}`,
              }, {
                type: 'home',
                text: `${object.vCard_home_email ? object.vCard_home_email : ""}`,
              }],
              phones: [{
                type: 'work',
                text: `${object.vCard_work_phone ? object.vCard_work_phone : ""}`,
              }, {
                type: 'voice',
                text: `${object.vCard_mobile_phone ? object.vCard_mobile_phone : ""}`,
              }, {
                uri: '',
              }],
              urls: [{
                type: 'internet',
                uri: `${object.vCard_url ? object.vCard_url : ""}`,
              }, {
                type: 'personal',
                uri: '',
              }],
              photos: [{
                type: 'work',
                uri: '',
              }],
              notes: [{
                text: `${object.vCard_note ? object.vCard_note : ""}`,
              }, {
                text: `${object.vCard_another_note ? object.vCard_another_note : ""}`,
              }],
            });
            // console.log(vcardContent);

            //             var file = new Blob(
            //               [
            //                 `BEGIN:VCARD
            // VERSION:3.0
            // N:${object.vCard_lastName};${object.vCard_firstName};;;
            // FN:${object.vCard_firstName} ${object.vCard_lastName}
            // TITLE:${object.vCard_title}
            // URL:${object.vCard_url}
            // END:VCARD`,
            //               ],
            //               { type: "text/vcard;charset=utf-8" }
            //             );

            // var file = new Blob([vcardContent],
            //   { type: "text/vcard;charset=utf-8" }
            // );

            const url = window.URL.createObjectURL(new Blob([`${vcardContent}`], { type: "text/vcard;charset=utf-8" }));
            const link = document.createElement('a');
            link.download = `${object.vCard_lastName} ${object.vCard_firstName} (${object.vCard_title}).vcf`;
            link.href = url;
            document.body.appendChild(link);
            link.click();


            // let a = document.createElement("a");
            // a.download = `${object.vCard_lastName}${object.vCard_firstName}.vcf`;
            // a.href = URL.createObjectURL(file);

            // var reader = new FileReader();
            // if (navigator.userAgent.match("CriOS")) {
            //   reader.onloadend = (e) => {
            //     window.open(reader.result);
            //   };
            //   reader.readAsDataURL(file);
            // } else if (navigator.userAgent.match(/iPad|iPhone|Android/i)) {
            //   reader.onload = (e) => {
            //     window.location.href = reader.result;
            //   };
            //   reader.readAsDataURL(file);
            // } else {
            //   FileSaver.saveAs(
            //     file,
            //     `${object.vCard_firstName}${object.vCard_lastName}.vcf`,
            //     true
            //   );
            // }

          }
        });
      //  console.log(myUser);
      //  return fetch(
      //    "https://tabclixdata.s3.ca-central-1.amazonaws.com/Tomasz+Tarasiewicz.vcf"
      //  ).then((response) => {
      //    response.blob().then((blob) => {
      //      // Creating new object of PDF file
      //      const fileURL = window.URL.createObjectURL(blob);
      //      // Setting various property values
      //      let alink = document.createElement("a");
      //      alink.href = fileURL;
      //      alink.download = "https://tabclixdata.s3.ca-central-1.amazonaws.com/Tomasz+Tarasiewicz.vcf";
      //      alink.click();
      //    });
      //  });
      default:
      // code block
    }
  };
};

export default useActionHook;
