import React from 'react';
import FormStyles from "../form/fom.module.scss";



function Button(props) {
  const { LabelName, onClick, state } = props;

  // const { register } = useFormContext();
  
  return (
    <div className="">
      <button
        type="submit"
        value="Submit"
        className={`${FormStyles.button} ${FormStyles.tabclix__w_button}`}
        onClick={onClick}
        name="Mybutton"
        // {...register(`Mybutton`)}
        disabled={state}
      >
        {LabelName ? LabelName : 'Submit'}
      </button>
    </div>
  );
}

export default Button