import React from 'react';
import ReactPlayer from 'react-player';



function Video(props) {

    
  return (
    <div className='player-container'>
      <ReactPlayer
        className="react-player"
        url={props.path}
        playing={props.playing}
        loop={props.loop}
        controls={props.controls}
        light={props.light}
        muted={props.muted}
      />
    </div>
  );
}

export default Video