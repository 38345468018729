import React from 'react';
import TypoCss from "./typography.module.scss";


function H1(props) {

  const { text } = props;
  // console.log(props);

  const dynamicCss =
  {
    fontSize: props.font_size ? `${props.font_size}px` : '20px',  
    marginTop: props.margin_top ? `${props.margin_top}px`: '0px', 
    marginBottom: props.margin_bottom ? `${props.margin_bottom}px`: '20px',
    lineHeight: props.font_size ? `${(props.font_size * 1.4 )}px` : '22.4px',  
  }


  return <h1 style={dynamicCss} className={TypoCss.tbx_h1}>{text}</h1>;
}

export default H1