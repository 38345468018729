import React, {useState} from "react";
import { useFormContext } from "react-hook-form";
import CheckCss from "./checkbox.module.scss";



function Checkbox(props) {
  
  const { register } = useFormContext();
  const [isChecked, setIsChecked] = useState(false);

  return (
    <>
      <label className={`${CheckCss.tabclix_checkbox_field} `}>
        <input
          type="checkbox"
          className={`${CheckCss.tabclix__checkbox}`}
          name={props.name}
          {...register(`${props.name}`)}
          onChange={() => {
            setIsChecked(!isChecked);
          }}
          value={isChecked}
        />
        <svg
          className={`${CheckCss.checkbox} ${
            isChecked ? `${CheckCss.checkbox__active}` : ""
          }`}
          // This element is purely decorative so
          // we hide it for screen readers
          aria-hidden="true"
          viewBox="0 0 15 11"
          fill="none"
        >
          <path
            d="M1 4.5L5 9L14 1"
            strokeWidth="2"
            stroke={isChecked ? "#fff" : "none"} // only show the checkmark when `isCheck` is `true`
          />
        </svg>
        <span className="w-form-label" htmlFor="radio">
          {props.text}
        </span>
      </label>
    </>
  );
}

export default Checkbox;

// VERSION 1.0
// import React from 'react'
// import { useForm, FormProvider, useFormContext } from "react-hook-form";

// function Checkbox(props) {
//     const { register } = useFormContext();
//     return (
//         <>
//             <label class="w-checkbox checkbox-field">
//                 <div class="w-checkbox-input w-checkbox-input--inputType-custom checkbox w--redirected"/>
//                 <div className='round'>
//                 <input {...register(`${props.name}`)} type="checkbox" id="checkbox" name={props.name}  />
//                 <label for="checkbox"></label>
//                 </div>
//                 <span class="w-form-label" for="checkbox">Checkbox - Lorem Ipsum</span>
//             </label>
//         </>
//     )
// }

// export default Checkbox
