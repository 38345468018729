import React, { createElement } from "react";
import Card from '../components/common/Card'
import Search from "../components/common/Search";
import Form from "../components/common/form/Form";
import Input from "../components/common/form/Input";
import Header from '../components/common/Header'
import Gallery from "../components/common/Gallery";
import Image from "../components/common/Image";
import Video from "../components/common/Video";
import Button from "../components/common/button/Button";
import Checkbox from "../components/common/form/Checkbox";
import Radiobutton from "../components/common/form/Radiobutton";
import DonationButton from "../components/common/button/DonationButton";
import OtherDonation from "../components/common/form/OtherDonation";
import Inputgroup from "../components/common/form/Inputgroup";
import Textarea from "../components/common/form/Textarea";
import H1 from "../components/text/H1";
import P1 from "../components/text/P1";
import SpaceBlock from "../components/common/SpaceBlock";
import Gif from "../components/common/Gif";
import Hyperlink from "../components/text/Hyperlink";
import GoBackText from "../components/common/button/GoBackText";
import PaymentForm from "../components/payment/form/PaymentForm";
import ThankyouPage from "../components/payment/pages/ThankyouPage";
import GooglePay from "../components/payment/form/GooglePay";
import PaymentCard from "../components/payment/PaymentCard";
import H2 from "../components/text/H2";
import NavBarIcon from "../components/common/NavBarIcon";
import Calendly from "../components/calendly/Calendly";
import NewsLetter from "../components/common/form/NewsLetter";

const keysToComponent = {
  card: Card,
  search: Search,
  form: Form,
  input: Input,
  // header:Header,
  image: Image,
  gallery: Gallery,
  video: Video,
  button: Button,
  checkbox: Checkbox,
  radiobutton: Radiobutton,
  donationButton: DonationButton,
  paymentButton: DonationButton,
  otherDonation: OtherDonation,
  otherPayment: OtherDonation,
  inputgroup: Inputgroup,
  textarea: Textarea,
  p1: P1,
  h1: H1,
  h2: H2,
  spaceBlock: SpaceBlock,
  gif: Gif,
  hyperlink: Hyperlink,
  gobacktext: GoBackText,
  paymentForm: PaymentForm,
  thankyoupage: ThankyouPage,
  googlepay: GooglePay,
  paymentcard: PaymentCard,
  navIcon: NavBarIcon,
  calendly:Calendly,
  newsLetterSignupForm:NewsLetter
};

/*Renders a component with its info*/
export const renderComponent = (comp) => {
    // if (comp.type != `navIcon`){
    //     console.log(comp);
    // }
    /*if components type exists and it is info*/
    if (typeof keysToComponent[comp.type] !== 'undefined') {
        return comp &&
        (
            createElement(
                keysToComponent[comp.type],
                {...comp,}, 
                // comp.children?.map =  asks if array exists
                comp.children?.map(childComponent => renderComponent(childComponent))
                 )
        )  
        }else {
            // console.log("")
        }
    }
    

