import React from 'react'
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormCSS from "../../../common/form/fom.module.scss";
import TypoCss from '../../../text/typography.module.scss';



function YearPicker(props) {

   const { t } = useTranslation();
    const { onChange } = props;
    const {
      formState: { errors },
    } = useFormContext();


    var date = new Date();
    var year = date.getFullYear();

    const yearList = [];
    for (let index = 0; index < 10; index++) {
      yearList.push(year + index);
    }

    const years = yearList.map((year) => (
      <option value={`${year}`}>{year}</option>
    ));


  return (
    <>
      <label htmlFor="" className={TypoCss.tabclix__label}>
        {t("expiration_year")}
      </label>
      <select
        id="years"
        className={`${FormCSS.tabclix_input_field} ${FormCSS.tabclix__w_input}`}
        defaultValue="AAAA"
        onChange={onChange}
      >
        <option hidden value="AAAA">
          AAAA
        </option>
        {years}
      </select>
      <p className={`${FormCSS.error_form_message}`}>
        {errors.expiryYear?.message}
      </p>
    </>
  );
}

export default YearPicker