import React from "react";



export const removeClass = (classToFind, classToRemove) =>{

    const elements = document.querySelectorAll(classToFind);
  
    elements.forEach(element => {
      // Remove class from each element
      element.classList.remove(classToRemove);
    });
}

export const removeAllClass = (classToFind) => {
  const elements = document.querySelectorAll(classToFind);
// console.log(elements);
  elements.forEach((element) => {
    // Remove class from each element
    element.removeAttribute("class");
  });
};