import React, { useState, useRef } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector } from "react-redux";
import MonthPicker from "./components/MonthPicker";
import YearPicker from "./components/YearPicker";
import CreditCardElement from "./components/CreditCardElement";
import EmailElement from "../../common/form/Inputs/EmailElement";
import { useDispatch } from "react-redux";
import { tabclixActions } from "../../../store/data-slice";
import CvvElement from "./components/CvvElement";
import { useTranslation } from "react-i18next";
import FormStyles from "../../common/form/fom.module.scss";
import ButtonCss from "../../common/button/donationButton.module.scss";
import TypoStyles from "../../text/typography.module.scss";
import { getTabclixCode } from "../../../helpers/embed-code";
import GooglePay from "./GooglePay";

/** Payment Form element, responsible for making transactions by sending all
 * the data to the backend
 */
function PaymentForm(props) {

    const { t } = useTranslation();
    /** yup validation configuration */
    const requireFieldString = t("required_field");
    const schema = yup
      .object({
        CustomerEmail: yup.string().required(requireFieldString),
        cardNumber: yup
          .string()
          .min(19, t("enter_valid_card"))
          .max(19, t("enter_valid_card"))
          .matches(/^[0-9\s]+$/, t("only_allowed", { something: t("numbers") }))
          .required(requireFieldString),
        cardName: yup
          .string()
          .required(requireFieldString)
          .matches(
            /^[a-zàâçéèêëîïôûùüÿñæœ .-]*$/i,
            t("only_allowed", { something: t("alphabets") })
          ),
        cvv: yup
          .string()
          .min(3, t("invalid_cvv"))
          .max(6, t("invalid_cvv"))
          .matches(/^[0-9]+$/, t("only_allowed", { something: t("numbers") }))
          .required(requireFieldString),
        expiryYear: yup.string().required(requireFieldString),
        expiryMonth: yup.string().required(requireFieldString),
      })
      .required();

  const { loadingButttonMessage, submitButtonMessage} = props;

  /** Initial declarations */
  const dispatch = useDispatch();
  const [loading, setIsLoading] = useState(false);
  const [failedTransactionError, setFailedTransactionErrors] = useState(null);
  const thankyou = useSelector((state) => state.datareducer.thankYouPage);
  const donationAmount = useSelector((state) => state.donationReducer.currentDonationAmmount);
  const methods  = useForm({resolver: yupResolver(schema),});
  const { register, handleSubmit, control, formState: { errors }, } = methods; 


  /** formats credit card info to remove spaces */
  const formatCreditCard = data => {
    const cleanCardNumber = data.cardNumber.split(" ");
    const creditCard = [];
    cleanCardNumber.forEach((t, i) => {
      creditCard.push(t);
    });
    return creditCard.join("");
  }

  /** Sends all the info to backend */
  async function onSubmit (data) {
    // alert("submit");
    // console.log(data);
    setFailedTransactionErrors(null);
    setIsLoading(true);
    
    let userToken = getTabclixCode();
    

    data["cardNumber"] = formatCreditCard(data);
    console.log(data["cardNumber"]);
    data["amount"] = donationAmount;
    // console.log(data.amount);
    fetch(`${process.env.REACT_APP_CMS_URL}/api/checkout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        data: `${userToken}`,
         "Access-Control-Allow-Origin": "*"
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.payment) {
          // console.log("success");
          dispatch(tabclixActions.setSubContent({id : thankyou.parent_ids[0]}));
        } else {
          setIsLoading(false);
          setFailedTransactionErrors(t("payment_failed"));
          // console.log("failed");
        }
      })
      .catch((err) => {
        // console.log(err);
        return "payment failed";
      });
  };

  return (
    <FormProvider {...methods}>
      <GooglePay/>
      {failedTransactionError && (
        <div className={`${FormStyles.form_error_submission}`}>
          {" "}
          <h5>{failedTransactionError}</h5>
        </div>
      )}
      <form className="form-container" id="tabclix_donation_form__target" onSubmit={handleSubmit(onSubmit)}>
        <div className="">
          <label htmlFor="" className={`${TypoStyles.tabclix__label}`}>{t("card-holder_name")}</label>
          <input
            type="text"
            className={`${FormStyles.tabclix_input_field} ${FormStyles.tabclix__w_input}`}
            {...register("cardName")}
          />
          <p className={`${FormStyles.error_form_message}`}>
            {errors.cardName?.message}
          </p>
        </div>
        <div className="">
          <EmailElement />
        </div>
        <div className="">
          <CreditCardElement />
        </div>
        <div className="">
          <Controller
            name="expiryMonth"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <MonthPicker onChange={onChange} />
            )}
          />
          <div className="">
            <Controller
              name="expiryYear"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <YearPicker onChange={onChange} />
              )}
            />
          </div>
        </div>
        <div className="">
          <div>
            <CvvElement></CvvElement>
          </div>
          <div>
            <label htmlFor="" className={`${TypoStyles.tabclix__label}`}>{t("amount")} $</label>
            <input
              disabled
              value={donationAmount}
              className={`${FormStyles.tabclix_input_field} ${FormStyles.tabclix__w_input}`}
              type="text"
              {...register("amount")}
            ></input>
          </div>
        </div>
        <button
          disabled={loading}
          type="submit"
          className={`${FormStyles.button} ${FormStyles.tabclix__w_button} ${ButtonCss.tabclix_donation_button}`}
        >
          {loading ? `Loading...` : `${submitButtonMessage}`}
        </button>
      </form>
    </FormProvider>
  );
}

export default PaymentForm