import React from "react";
import NavIconCss from "./navbarIcon.module.scss";
import typoCss from "../text/typography.module.scss";
import $ from "jquery";
import navCss from "../../layout/navbar.module.scss";
import useActionHook from "../../hooks/useActionHook";

function NavBarIcon(props) {
  const { icon, description, id} = props;


  // console.log(props);

  const handleChangePage = (id) => {

    $(".tabclix__OpCl_target").removeClass("active");
    $(`#${id} > a > div >span`).addClass("active");
    document.body.style.overflow = "hidden";
    // Sends the ifram height to the parent window
    document.getElementById("tabclix___div_root").style.height = "100%";
    window.parent.postMessage("100%", "*");
  }; 

  return (
    <li
      key={id}
      id={id}
      className={NavIconCss.tabclix_icon_container}
      style={{ listStyle: "none" }}
      onClick={useActionHook({
        "type": "navIcon",
        "id": "367navIcon116",
        "parent_ids": [],
        "action": {
            "type": "changePage"
        },
        "order": 1,
        "description": "Info",
        "message": " ",
        "url": "",
        "icon": "tbx-info-outline"
    })}
    >
      <a
        className={`${navCss.w_inline_block} navigation-bar-link`}
        onClick={() => handleChangePage(id)}
      >
        <div className={`${NavIconCss.tabclix_mobile_bar_icon_container} `}>
          <span
            className={`${NavIconCss.tabclix_icon_mobile_bar} tbx-icon ${icon} tabclix__OpCl_target`}
          ></span>

          {description && (
            <div className={typoCss.tabclix__text_small}>
              <span className={NavIconCss.mobile_bar_icon_description}>
                {description}
              </span>
            </div>
          )}
        </div>
      </a>
    </li>
  );
}

export default NavBarIcon