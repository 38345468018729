import React from 'react'
import { renderComponent} from '../helpers/RenderComponent'

/**
 * Lists all the rendered components
 * 
 */
function RenderedComponent({value, key}) {
  const Myprops = value;
  // console.log(props);



  /** Use given props to render component with renderComponent*/
  return (
    <>
        {renderComponent(Myprops)}
    </>
  )
}

export default RenderedComponent