import React from 'react';
import Input from './Input';
import { donationFormActions } from '../../../store/form-donation-slice';
import { useDispatch } from 'react-redux';
import { useForm, FormProvider } from "react-hook-form";
import { tabclixActions } from '../../../store/data-slice';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from 'react-i18next';
import FormCss from "./fom.module.scss";

function OtherDonation(props) {
  // console.log(props);
  const { t } = useTranslation();

  const schema = yup.object({
    otherAmount: yup
      .number(t("greater_zero"))
      .typeError(t("greater_zero"))
      .positive(t("greater_zero"))
      .required(t("required_field")),
  });
  
  const dispatch = useDispatch();
  
  const formMethods = useForm({ resolver: yupResolver(schema) });
  const {
     handleSubmit,
     watch,
     formState: { errors },
    } = formMethods;
    
    const watchInput = watch("otherAmount", false);
  
  const { buttonText, inputPlaceholder } = props;

  const onSubmit = (data) => {
      // var = props.dispatch
      // dispatch action (varNAme)
      
      // console.log(data.otherAmount);
      dispatch(donationFormActions.setDonationAmmount(+data.otherAmount));
      dispatch(tabclixActions.setDirectSubcontent(props.id));
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={`${FormCss.donation_block} ${FormCss.other_donation_input_block}`}
        >
          <Input
            InputType="input"
            placeholder={`${inputPlaceholder}`}
            typeOfTheInput="number"
            name="otherAmount"
          />
          {watchInput && (
            <div className={`${FormCss.button_block} autre-button`}>
              <button
                type="submit"
                value="Submit"
                className={`${FormCss.button} ${FormCss.tabclix__w_button}`}
              >
                {buttonText}
              </button>
            </div>
          )}
        </div>
      </form>
      {watchInput && (
        <p className={`${FormCss.error_form_message}`}>
          {errors.otherAmount?.message}
        </p>
      )}
    </FormProvider>
  );
}

export default OtherDonation;