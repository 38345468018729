import React from 'react'
import { useFormContext } from "react-hook-form";
import FormCss from "./fom.module.scss";
import TypoCss from '../../text/typography.module.scss';



function Textarea(props) {

    const { placeholder, label, cols, row, forLabel, inputId, name } = props;
    const { register } = useFormContext();
  
    return (
      <>
        <label htmlFor={forLabel} className={TypoCss.tabclix__label}>{label}</label>
        <textarea
          className={`${FormCss.tabclix_input_field} ${FormCss.tabclix__w_input}`}
          placeholder={placeholder}
          id={inputId}
          name={name}
          rows={row}
          cols={cols}
          {...register(`${name}`)}
        ></textarea>
      </>
    );
}

export default Textarea