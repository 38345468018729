import { configureStore } from "@reduxjs/toolkit";
import dataSlice from "./data-slice";
import dataReducer from "./data-slice";
import donationReducer from './form-donation-slice';


// because dataReducer is already a reducer, we dont need to put slice.reducer
const store = configureStore({
    reducer: {
        datareducer:dataReducer,
        donationReducer:donationReducer
    }
});

// EXAMPle if you need to have multiple reducers from diffrent slices
// const store = configureStore({
//     reducer: {
//         counter: counterSlice,
//         favorite: favoriteSlice
//     }
// });




export default store;


