import React from 'react';
import { useSelector } from 'react-redux';
import Image from './Image';
import Video from './Video';
import styled from 'styled-components';



function Gallery(props) {

    const MediasStyle = styled.div`
        width: 100%;
        display: block;
    `;

    const data = useSelector((state) => state.datareducer.AllData);

    const component = data.map(function (eachObject) {
    
        return eachObject.parent_ids.map(function (eachIdofeachComponent) {
            if (eachIdofeachComponent === props.id) {
                if(eachObject.type === 'video' ){
                    return <Video {...eachObject} />
                }
                if(eachObject.type === 'image'){
                    return <Image {...eachObject} />
                }
            }
        })
    })

  return <MediasStyle>{component}</MediasStyle>;
}

export default Gallery