import React from 'react'
import ImageCss from "./image.module.scss";


function Gif(props) {
  return (
    <img
      crossorigin
      src={`${process.env.REACT_APP_IMAGE_PATH}/${props.image}`}
      loading="lazy"
      className={ImageCss.image}
    />
  );
}

export default Gif