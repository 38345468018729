import React, { createContext, useReducer, useContext } from 'react';
import { v4 } from 'uuid';
import Messages from './Messages';


const NotificationContext = createContext();

function MessageProvider(props) {

   

    //when dispatch is called, it  puts the defined object into action
    //the second state is the previous state
    const [state, dispatch] = useReducer((state, action) => {

        // perform diffrent logic on state depending on the action type
        switch (action.type) {
            case "ADD_MESSAGE":
                return [...state, { ...action.payload }];
            case "REMOVE_MESSAGE":
                // removes element that only have an id of the element passed in props
                // if return false, removes it from the array + return automatically an array
                return state.filter(element => element.id !== action.id);
            default:
                return state
        }
    },
        []);

    // value={dispatch} lets us call any of action.type direcly to the state
    // value is accessible from anywhere nested inside   <MessageProvider>  </MessageProvider> 
    return <NotificationContext.Provider value={dispatch} >
        <div className='message-wrapper'>
            {state.map((message) => {
                return <Messages dispatch={dispatch} key={message.id} {...message} />
            })}
        </div>
        {props.children}

    </NotificationContext.Provider>;
}

// custom hook (so we don't have to always import NotificationContext), 
// we just call useNotification
export const useNotification = () => {
    // Allows to expose the dispatch to every nested component 
    const dispatch = useContext(NotificationContext);
    //contains predefined data
    return (props) => {
        dispatch(
            {
                type: "ADD_MESSAGE",
                payload: {
                    id: v4(),
                    ...props
                }
            })
    }
}

export default MessageProvider;
