import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { getTabclixCode } from "../../helpers/embed-code";
import { v4 as uuid } from 'uuid';
import { useTracking } from 'react-tracking';
import axios from "axios";


function ConfigWrapper(props) {
  const [stripeObject, setStripeObject] = useState(null);
  const { trackEvent } = useTracking();
  
  useEffect(() => {

    // if there is a payment form it means there will be payment,
    // just loop and see if present then proceed
    // if not set variable to zero and will return with empty stipe element
    
    let code = getTabclixCode();
    // localStorage.removeItem(`__trEventSs_${code}`);
    try{

      if (!localStorage.getItem(`__trEventSs_${code}`)) {
        localStorage.setItem(`__trEventSs_${code}`, JSON.stringify(uuid()));

        axios
        .get("https://ipapi.co/json/")
        .then((response) => {
          let data = response.data;
          trackEvent({ 
            TrackingType: 'userLocationEvent',
            Trackcountry: data.country_name,
            TrackCity:  data.city,
            TrackIp: data.ip,
            TrackRegionCode: data.region_code,
            TrackCurrency: data.currency,
            TrackLatitudeLongitude: `${data.latitude};${data.longitude}`,

          })
        })
        .catch((error) => {
          // console.log(error);
        });
      }
    }catch (e) {
      // console.log(e);
    }
    
      

    const fetchStripeObject = async () => {

      let userToken = getTabclixCode();

      try {
      const stripekey = await fetch(
          `${process.env.REACT_APP_CMS_URL}/api/stripe-pubkey`,{
            method: "get",
            headers: {
              data: `${userToken}`,
              "Access-Control-Allow-Origin": "*",
            },}
        );

        const userStripeKey = await stripekey.text();
         const res = await loadStripe(String(userStripeKey));
         setStripeObject(res);

        } catch (error) {
            return <>{props.children}</>;
          }
      // Oauth
      // const FormData = require('form-data');
      // let form_data = new FormData();

      // form_data.append('grant_type', 'client_credentials');
      // form_data.append('client_id', process.env.REACT_APP_CLIENT_ID);
      // form_data.append('client_secret', process.env.REACT_APP_CLIENT_SECRET);
      // form_data.append('scope', '');

      // try {
      // const authorization = await axios.post(
      //   `${process.env.REACT_APP_CMS_URL}/oauth/token`,form_data,{
      //       headers: {
      //         "Access-Control-Allow-Origin": "*",
      //       },
      //   });

      //   const authorizationResponse = await authorization.data;

      //   const response = await axios.get(
      //     `${process.env.REACT_APP_CMS_URL}/api/stripe-pubkey`,{
      //         headers: {
      //             Authorization:`Bearer ${authorizationResponse.access_token}`,
      //             data: userToken,
      //             "Access-Control-Allow-Origin": "*",
                  
      //           },
      //       });

      // const stripekey = await response.data;

      // // Public key
      // // const stripekey = await fetch(
      // //   `${process.env.REACT_APP_CMS_URL}/api/stripe-pubkey`,
      // //   {
      // //     method: "get",
      // //     headers: {
      // //       data: `${userToken}`,
      // //       "Access-Control-Allow-Origin": "*",
      // //     },
      // //   }
      // // );
      //   const userStripeKey = await stripekey.text();
      //   const res = await loadStripe(String(userStripeKey));
      //   setStripeObject(res);

      // } catch (error) {
      //   return <>{props.children}</>;
      // }

      if (!stripeObject) {
        return <>{props.children}</>;
      }
    };
    fetchStripeObject();
  }, []);

  return (
    <Elements stripe={stripeObject}>
      <>{props.children}</>
    </Elements>
  );
}

export default ConfigWrapper;
