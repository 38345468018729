import React from 'react'
import { useSelector } from 'react-redux';
import RenderedComponent from './RenderedComponent'
import List from './List';
import Header from '../components/common/Header';
import {sortParentIdComponents, sortParentIdComponent } from '../helpers/sort-components';
import ConfigWrapper from '../components/wrappers/ConfigWrapper';
import PageCss from "./pageApp.module.scss";
import { findComponentsById  } from '../helpers/find-data';
import Navbar from './Navbar';

/**
 * Component responsible the main calls of logic
 * 
 */
function PageApp() {

    const currentPage = useSelector(state => state.datareducer.currentPage);
    const AllData = useSelector(state => state.datareducer.AllData)
    const subContent = useSelector(state => state.datareducer.currentSubContentId)
    let showBack = false;
    let sortComponents = []

    /**Responsible to sort components by their order, in subcontent mode or in normal mode */
    if (subContent === null) {

      // const sort = findComponentsById(currentPage, AllData);
      // sortComponents = sortParentIdComponent(sort);
      // console.log(sortComponents);
   
        sortComponents = sortParentIdComponents(currentPage, AllData ); 
        // console.log(sortComponents);
        
    } else {

        // const sort = findComponentsById(subContent, AllData);
        // sortComponents = sortParentIdComponent(sort);
        sortComponents = sortParentIdComponents(subContent, AllData );
        showBack = true;
    }

    const component = sortComponents.map((orderedComponent, index) => <RenderedComponent value={orderedComponent}  key={index}/>)
    // if (currentPage === 0){
    //     // alert("What");
    // }
      return (
        <ConfigWrapper>
          <div className={PageCss.main_layout_wrapper}>
            <div
              className={`${PageCss.main_section_wrapper} wf-section`}
              id="testing"
              tabIndex="1"
            >
              <div className={PageCss.app_main_layout}>
                <div className={PageCss.app_main_layout_wrapper}>
                  <Header showBack={showBack}></Header>
                  <List value={component} />
                </div>
              </div>
            </div>
            <Navbar/>
          </div>
          
        </ConfigWrapper>
      );
}

export default PageApp