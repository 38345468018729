import React from 'react'
import {
  FacebookShareButton,
  TwitterShareButton
} from "react-share";



function ThankyouPage(props) {

    const {
      shareUrl,
      shareFacebook,
      ShareTwitter,
      twitterComponent,
      facebookComponent,
    } = props;

  return (
    <>
      <div id="thankyou_payment_donation__target">{props.children}</div>

      {shareFacebook && (
        <FacebookShareButton
          children={
            <img
              loading="lazy"
              width="50"
              alt=""
              src={`${process.env.REACT_APP_ICON_URL}/images/${facebookComponent}`}
            />
          }
          url={shareUrl}
          resetButtonStyle={false}
        ></FacebookShareButton>
      )}

      {ShareTwitter && (
        <TwitterShareButton
          children={
            <img
              loading="lazy"
              width="50"
              alt=""
              src={`${process.env.REACT_APP_ICON_URL}/images/${twitterComponent}`}
            />
          }
          title="summary"
          url={shareUrl}
        ></TwitterShareButton>
      )}
    </>
  );
}

export default ThankyouPage