import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ThankyouPage from './payment/pages/ThankyouPage';
import PageApp from '../layout/PageApp';

function MyRoutes() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<PageApp />}></Route>
      </Routes>
    </>
  );
}

export default React.memo(MyRoutes)