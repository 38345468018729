import React from 'react'
import TypoCss from "./typography.module.scss";



function Title({title}) {

  return <div className={TypoCss.card_title}>{title}</div>;
}

export default Title