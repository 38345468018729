import React from 'react'
import useActionHook from '../../hooks/useActionHook';
import TypoCss from "./typography.module.scss";



function Hyperlink(props) {
  const {  a } = props;

  const dynamicCss =
  {
    fontSize: props.font_size ? `${props.font_size}px` : '14px',
    marginTop: props.margin_top ? `${props.margin_top}px` : '0px',
    marginBottom: props.margin_bottom ? `${props.margin_bottom}px` : '20px',
    lineHeight: props.font_size ? `${(props.font_size * 1.4)}px` : '22.4px',
  }

  return (
    <>
      <a className={TypoCss.tabclix__a} onClick={useActionHook(props)} style={dynamicCss}>
        {a}
      </a>
      <br />
    </>
  );
}

export default Hyperlink;