import React, {  useEffect, useState } from 'react'
import { PaymentRequestButtonElement, useStripe } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { tabclixActions } from "../../../store/data-slice";
// import { PaymentRequest } from '@stripe/stripe-js';

/** This component is responsible for Stripe GooglePay and Apple Pay payments */
function GooglePay() {

      const stripe = useStripe();
      const dispatch = useDispatch();
      const thankyou = useSelector((state) => state.datareducer.thankYouPage);
      const AllData = useSelector((state) => state.datareducer.AllData);
      const [paymentRequest, setPaymentRequest] = useState(null);
      const donationAmount = useSelector((state) => state.donationReducer.currentDonationAmmount);

      useEffect(() => {
       const customAmount = +donationAmount;
        const myAmount = customAmount * 100;
       if (stripe) {
        // console.log(myAmount);
         /** this will appear on the customer on the payment sheet - payemnt request object*/
         const pr = stripe.paymentRequest({
           country: "CA",
           currency: "cad",
           total: {
             label: "Paiement",
             amount: myAmount ,
           },
           requestPayerName: true,
           requestPayerEmail: true,
         });

        //  if (pr._backingLibraries.APPLE_PAY){              
        //     window.parent.postMessage({ type: "applepay" }, "*");
        //  }
           /** Determines if we can display GooglePay or applePay button */
           pr.canMakePayment().then((result) => {
             if (result) {
               setPaymentRequest(pr);
             } else {
              //  console.log("");
             }
           });

          //  const description = AllData.filter(object => object.type == "configuration");
         /** 1- listener for when the payment had gone throught google/apple pay */
         pr.on("paymentmethod", async (ev) => {
           // create fetch request
           let params = new URL(document.location).searchParams;
           let userToken = params.get("tabclix");

           const customStripeConfigs = AllData.find((object) => {
             if (object.type === "configurations") {
               return object.stripe_description;
             }
           });
          //  console.log(customStripeConfigs.stripe_description);
           
           /** we create payment intent on our server and take client secret from that intent*/
           const myData = await fetch(
             `${process.env.REACT_APP_CMS_URL}/api/paymentIntent`,
             {
               method: "POST",
               headers: {
                 "Content-Type": "application/json",
                 data: `${userToken}`,
                 amount: `${myAmount}`,
                 "Access-Control-Allow-Origin": "*",
               },
               body: JSON.stringify({
                 paymentMethodType: "card",
                 currency: "cad",
                 description: "Tabclix transaction",
               }),
             }
           );
           const data = await myData.json();

           // Confirm the PaymentIntent without handling potential next actions (yet).
           const { paymentIntent, error: confirmError } =
             await stripe.confirmCardPayment(
               data.client_secret,
               { payment_method: ev.paymentMethod.id },
               { handleActions: false }
             );

           if (confirmError) {
             // Report to the browser that the payment failed, prompting it to
             // re-show the payment interface, or show an error message and close
             // the payment interface.
             ev.complete("fail");
           } else {
             // Report to the browser that the confirmation was successful, prompting
             // it to close the browser payment method collection interface.
             ev.complete("success");
             // Check if the PaymentIntent requires any actions and if so let Stripe.js
             // handle the flow. If using an API version older than "2019-02-11"
             // instead check for: `paymentIntent.status === "requires_source_action"`.
             if (paymentIntent.status === "requires_action") {
               // Let Stripe.js handle the rest of the payment flow.
               const { error } = await stripe.confirmCardPayment(
                 data.client_secret
               );
               if (error) {
                 alert("Payment fail");
                 // The payment failed -- ask your customer for a new payment method.
               } else {
                 // The payment has succeeded.
                 alert("Payment success");
               }
             } else {
               // The payment has succeeded.
              //  console.log("Payment success");
               dispatch(tabclixActions.setCurrentPage(thankyou.parent_ids[0]));
             }
           }
         });
       }
     }, [stripe, donationAmount]);

    if (paymentRequest) {
         const options = {
           paymentRequest,
           style: {
             paymentRequestButton: {
               type: "default",
               // One of 'default', 'book', 'buy', or 'donate'
               // Defaults to 'default'

               theme: "dark",
               // One of 'dark', 'light', or 'light-outline'
               // Defaults to 'dark'

               height: "42px",
               // Defaults to '40px'. The width is always '100%'.
             },
           },
         };
      return (
        <>
          <PaymentRequestButtonElement options={options} />
        </>
      ); 
    }

  

  return <>{paymentRequest}</>;
}

export default GooglePay