import React from "react";
import { useFormContext } from "react-hook-form";
import RadioCss from "./radioBtn.module.scss";



function Radiobutton(props) {
  const { text, inputId, name,value } = props;
  const { register } = useFormContext();

  return (
    <>
      <label className={`${RadioCss.tabclix__label_radio}`}>
        <input
          {...register(`${name}`)}
          className={`${RadioCss.tabclix__radio}`}
          type="radio"
          value={value}
          id={inputId}
          name={name}
        />
        <span className="w-form-label" htmlFor="radio">
          {text}
        </span>
      </label>
    </>
  );
}

export default Radiobutton;