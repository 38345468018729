import React from 'react'
import TypoCss from "./typography.module.scss";


function P1(props) {

    const {text, bold} = props
    // console.log(props);

    const dynamicCss =
    {
      fontSize: props.font_size ? `${props.font_size}px` : '14px',  
      marginTop: props.margin_top ? `${props.margin_top}px`: '0px', 
      marginBottom: props.margin_bottom ? `${props.margin_bottom}px`: '20px',
      lineHeight: props.font_size ? `${(props.font_size * 1.4 )}px` : '22.4px',  
    }

  return (
    <p style={dynamicCss} className={`${TypoCss.tbx_p1} ${bold && TypoCss.bold_text} ${TypoCss.skip_line}`} >
      {text}
    </p>
  );
}

export default P1;