import React from 'react'
import { useDispatch } from 'react-redux';
import { tabclixActions } from '../../../store/data-slice';



function GoBackText(props) {

    const {text} = props;
    const dispatch = useDispatch()
    
    const callGoBack = () => {
        dispatch(tabclixActions.goBack())
    }

  return (
    <a className="a" onClick={callGoBack}>
      {text}
    </a>
  );
}

export default GoBackText