import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    currentDonationAmmount: 5,
};


export const DonationSlice = createSlice({
    name: 'donationData',
    initialState: initialState,
    reducers: {
        setDonationAmmount(state, action){
            state.currentDonationAmmount = action.payload;
        }
    }

})

//To get hold of  unique action identifiers, 
//we can use the counterSlice and access .actions = object full of keys that match the reducers name in the reducer area
// we should also be able to export the actions
export const donationFormActions = DonationSlice.actions;

// we dont export the entire slice but only its reducers 
export default DonationSlice.reducer;