import React from 'react'
import { useFormContext } from "react-hook-form";
import FormCSS from "../../../common/form/fom.module.scss";
import TypoCss from "../../../text/typography.module.scss";



function CvvElement() {

    const {
      register,
      formState: { errors },
    } = useFormContext();

  return (
    <>
      <label htmlFor="" className={TypoCss.tabclix__label}>CVV/CVC</label>
      <input
        type="text"
        className={`${FormCSS.tabclix_input_field} ${FormCSS.tabclix__w_input}`}
        {...register("cvv")}
        maxLength={6}
      />
      <p className={`${FormCSS.error_form_message}`}>{errors.cvv?.message}</p>
    </>
  );
}

export default CvvElement