import React from 'react'
import { useForm, FormProvider } from "react-hook-form";
import { useTracking } from 'react-tracking';

function Form(props) {

  const  formMethods = useForm();
    const { trackEvent } = useTracking();

  const {
    handleSubmit,
  } = formMethods;

  const onSubmit = (data) => {
    trackEvent({
      TrackingType: 'ActionEvent',
      TrackEvent: "fromSubmission",
      TrackEventType: `mail subscription`,
      TrackNumberCalled: `${props.url}`,
      TrackPlaceCalled: `${props.message}`,
    });
    // var = props.dispatch
    // dispatch action (varNAme)
    // alert(JSON.stringify(data, null, 4));
    alert("Thank you");
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>{props.children}</form>
    </FormProvider>
  );
}


export default Form