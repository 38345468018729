import generate from './VCARD';


export {
  generate,
};

export default {
  generate,
};
