import React from "react";
import TypoCss from "./typography.module.scss";



function H2(props) {

  const { text } = props;

  return <div className={TypoCss.tbx_h2}>{text}</div>;
}

export default H2;
