import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tabclixActions } from '../../store/data-slice';
import $ from 'jquery';
import { useTranslation } from "react-i18next";
import HeaderCss from "./header.module.scss"
import typoCss from "../text/typography.module.scss";
// import { UseCloseRoot } from "../../hooks/handle-root-size";


function Header(props) {

  const { t } = useTranslation();
  const { showBack } = props

    const dispatch = useDispatch()
    const currentPage = useSelector(state => state.datareducer.currentPage)
    const allHeaders = useSelector(state => state.datareducer.headers)
    const allData = useSelector((state) => state.datareducer.AllData);
    const currentHeader = allHeaders.find(header => header.parent_ids[0] === currentPage);

    // used for when we need a profile overlay
    // const profile = allData.find((object) => {
    //   if (object.type === "profile") {
    //     return object;
    //   }
    // });
    const brandLogo = allData.find((object) => {
      if (object.type === "configurations") {
        return object;
      }
    });
    
    const callGoBack = () => {
        dispatch(tabclixActions.goBack())
    }
    

    /** Close the tabs */
    const closeTab = () => {

      // Dispatch null so there is no page visible
      dispatch(tabclixActions.setCurrentPage(null));
      $(".tabclix__OpCl_target").removeClass("active");
      document.body.style.overflow = "";

      
      
          
      document.getElementById("tabclix___div_root").style.height = "60px";    
      // send the height to Iframe
      window.parent.postMessage("60px", "*");
    }

    const IconOrImage = () => {
      
      if(brandLogo && brandLogo.brand_logo){
        return  <img alt="header" src={`${brandLogo.brand_logo}`} loading="lazy" className={`${HeaderCss.header_icon} ${HeaderCss.header_img}`} crossorigin />
      }else if(currentHeader && currentHeader.image){
        return  <img alt="header" src={`${currentHeader.image}`} loading="lazy" className={`${HeaderCss.header_icon} ${HeaderCss.header_img}`} crossorigin />
      }else if(currentHeader !== undefined && currentHeader.icon){
        return  <div className={`tbx-icon ${HeaderCss.header_icon} ${currentHeader.icon}`} ></div>
      }else return
    }

    return (
      <div className={`${HeaderCss.header_container}`}>
        {IconOrImage()}

        <div>
          {currentHeader && (
            <h2 className={`${HeaderCss.header_title} ${typoCss.globalH2}`}>
              {currentHeader.title}
            </h2>
          )}
          {currentHeader && (
            <div className={typoCss.tabclix__text_small}>
              {t("powered_by")}{" "}
              <span className={HeaderCss.text_span_4}>
                Tabclix
              </span>
            </div>
          )}
        </div>

        {/* button need to be a component */}
        <div className={HeaderCss.header_button_container}>
          {showBack && <a  className="back-button w-inline-block">
            <div className="back-button" onClick={callGoBack}>
              arrow_back
            </div>
          </a>}

          <div onClick={closeTab} className={HeaderCss.x_button_block}>
            <div className="close-button">close</div>
          </div>
        </div>
      </div>
    );
}

export default React.memo(Header)