import api from "./api";
import { getTabclixCode } from "../helpers/embed-code";

let code = getTabclixCode()

const URLS = {
    fetchCssURL: `/api/css/${code}`,
    fetchContentURL: `/api/data/${code}`,
    fetchAuthUrl: '/oauth/token',
}

export async function  fetchCss (authToken) {

    return api.get(URLS.fetchCssURL, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'userID': code,
            'Authorization': `Bearer ${authToken}`,
        }
    })
}


export async function fetchContent (authToken) {

    return api.get(URLS.fetchContentURL, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'userID': code,
            'Authorization': `Bearer ${authToken}`,
        }
    })
}

export async function fetchAuth() {
    const FormData = require('form-data');
    let form_data = new FormData();

    form_data.append('grant_type', 'client_credentials');
    form_data.append('client_id', process.env.REACT_APP_CLIENT_ID);
    form_data.append('client_secret', process.env.REACT_APP_CLIENT_SECRET);
    form_data.append('scope', '');

    return api.post(URLS.fetchAuthUrl, form_data, {
        headers: {
            'Access-Control-Allow-Origin': '*',
        }
    })
}

