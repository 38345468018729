import React from 'react'
import { InlineWidget } from "react-calendly";



function Calendly(props) {

  return (
    <div>
      <InlineWidget
        url={props.url}
        pageSettings={{
          backgroundColor: "e9ecef",
          primaryColor: "33a7ff",
          textColor: "212529",
        }}
        styles={{
          height:"1400px",
        }}
      />
    </div>
  );
}

export default Calendly