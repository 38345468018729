import React from 'react'
import TypoCss from "./typography.module.scss";



function Description({description, bold=false}) {
  return (
    <div className={`${TypoCss.card_description} ${TypoCss.medium_text_size} ${bold && TypoCss.bold_text}`}>
      {description}
    </div>
  );
}

export default Description