import React, { useState } from 'react'
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormCSS from "../../../common/form/fom.module.scss";
import TypoCss from "../../../text/typography.module.scss";


function CreditCardElement() {

  const { t } = useTranslation()
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const [card, setCard] = useState("");



  const handleCardDisplay = () => {

    if (card && card.length > 5 && card.indexOf(' ') === -1 ) {
      let joy = card.match(/.{1,4}/g);
      setCard(joy.join(' '));
      console.log(joy.join(' '));
    }

    console.log(card);


    const rawText = [...card.split(" ").join("")]; // Remove old space
    const creditCard = []; // Create card as array
    rawText.forEach((t, i) => {

      if (i % 4 === 0 && i !== 0) creditCard.push(" "); // Add space
      creditCard.push(t);
    });
    return creditCard.join(""); // Transform card array to string
  };
  return (
    <>
      <label htmlFor="" className={TypoCss.tabclix__label}>{t("card_number")}</label>
      <input
        type="text"
        className={`${FormCSS.tabclix_input_field} ${FormCSS.tabclix__w_input}`}
        maxLength={19}
        {...register("cardNumber")}
        onKeyUp={(e) => setCard(e.target.value)}
        value={handleCardDisplay()}
      />
      <p className={`${FormCSS.error_form_message}`}>{errors.cardNumber?.message}</p>
    </>
  );
}

export default CreditCardElement