import React from "react";
import Title from "../text/Title";
import Description from "../text/Description";
import Icon from "./Icon";
import { useSortEvents } from "../../helpers/tracking-events";
import cardStyles from "./card.module.scss";
import useActionHook from "../../hooks/useActionHook";



/**
 * Shows card components
 *
 */
function Card(props) {

  const {
    id,
    order,
    description,
    paragraph,
    icon,
    message,
    iconTitle,
    place,
    address,
    iconText,
    url,
  } = props;

  const isSubtitleBold = message && paragraph ? true: false ;


  const selectedEvents = useSortEvents(props);

  return (
    <>
      <li id={`card${id}`} className={`${cardStyles.cards}`} key={id} style={{ listStyle: "none" }}>
        <div
          style={{ animationDelay: `${order * 0.05}s` }}
          className={`card_target ${cardStyles.card_animation}`}
        >
          <a
            onClick={useActionHook(props)}
            className={`card-link tabclix__action_${props.action.type}`}
          >
            <div
              target="_blank"
              className={`${cardStyles.card_content_block}`}
              onClick={selectedEvents}
            >
              {iconTitle && (
                <div
                  className={`${cardStyles.title_icon_container} ${cardStyles.payment}`}
                >
                  <img
                    src={iconTitle}
                    loading="lazy"
                    className={`${cardStyles.payment_img}`}
                    alt=""
                  />
                </div>
              )}
              <div
                className={`card_target  ${cardStyles.card_texts} ${cardStyles.card_text_animation}`}
              >
                <div className={`${cardStyles.card_texts}`}>
                  {description && <Title title={description}></Title>}
                  {place && <Title title={place}></Title>}
                  {address && <Description description={address}></Description>}
                  {message && <Description bold={isSubtitleBold} description={message}></Description>}
                  {paragraph && <Description description={paragraph}></Description>}
                </div>
              </div>
              {icon && (
                <div className={`card_target ${cardStyles.icon_animation}`}>
                  <div
                    className={`${cardStyles.icon_container} ${cardStyles.icon_animation}`}
                    style={{ animationDelay: `0.${order}s` }}
                  >
                    <div className="tbx-card-icon">
                      <Icon icon={icon}></Icon>
                    </div>
                  </div>
                </div>
              )}
              {iconText && (
                <p
                  className={`${cardStyles.card_right_border_icon} ${cardStyles.tabclix_text_large}`}
                >
                  {iconText}
                </p>
              )}
              {/* {image && (
                <div
                  className={`${cardStyles.card_right_border_image} `}
                >
                  <img src={`/images/${image}`} alt="" />
                </div>
              )} */}
            </div>
          </a>
        </div>
      </li>
    </>
  );
}

export default Card;
